import React from 'react'
import { graphql } from 'gatsby'
import Content from '../components/Content'
import Layout from '../components/Layout'
import './ChallengePage.css'


export const ThanksPageTemplate = ({
  body,
  sectionNext,
  h1,
  h2Next,
  prize1,
  prize2,
  prize3
}) => (
    <main className="Challenge">
      <section className="section Challenge--Section1">
        <div className="container Challenge--Section1--Container">
          <div>
            <h1 className="pink"><Content source={h1} /></h1>
            <Content source={body} />
            <h2> <Content source={h2Next} /></h2>
            <Content source={sectionNext} />
            <div className="Grid--Container-3-Col">
              <div className="Grid--Col">
                <span className="Challenge--Prize">1<span className="Challenge--Prize-suffix">st</span></span> <br /><br />
                <Content source={prize1} />
              </div>
              <div className="Grid--Col Challenge--Middle-Col">
                <span className="Challenge--Prize">2<span className="Challenge--Prize-suffix">nd</span></span> <br /><br />
                <Content source={prize2} />
              </div>
              <div className="Grid--Col">
                <span className="Challenge--Prize">3<span className="Challenge--Prize-suffix">rd</span></span> <br /><br />
                <Content source={prize3} />
              </div>
            </div>

          </div>
        </div>

      </section>

    </main>
  )

const ThanksPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ThanksPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ThanksPage

export const pageQuery = graphql`
  query ThankePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        sectionNext
        h1
        h2Next
        prize1
        prize2
        prize3
      }
    }
  }
`
